<template>
  <div>
    <b-row>
      <b-col class="d-flex flex-row align-items-center mb-2">
        <h2 class="mr-2 mb-0">
          {{ $t('costResume') }}
        </h2>
        <b-button
          size="sm"
          class="d-flex align-items-center"
          variant="outline-primary"
          @click="showTable = !showTable"
        >
          <feather-icon
            :icon="showTable?'EyeIcon':'EyeOffIcon'"
            class="mr-1"
          />
          <span>
            {{ showTable ? $t('show'):$t('hide') }}
          </span>
        </b-button>
      </b-col>
    </b-row>
    <b-row :class="{ 'd-none': showTable }">
      <b-col
        sm="12"
        class="print"
      >
        <b-card
          class=""
          no-body
        >
          <b-table
            :no-border-collapse="true"
            responsive
            :items="items"
            :fields="fields"
            class="mb-0 print-table"
          >
            <!-- We are using utility class `text-nowrap` to help illustrate horizontal scrolling -->
            <template #head(supplier)="row">
              <div class="text-nowrap">
                {{ row.label }}
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    quoteItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [],
      fields: [],
      showTable: true,
    }
  },
  computed: {
    ...mapGetters('app', ['currentBreakPoint', 'isSmallScreen']),
  },
  watch: {
    quoteItems: {
      handler(value) {
        let branchesComparative = []
        const suppliersWithProducts = {}
        value.forEach(item => {
          branchesComparative = [
            ...branchesComparative,
            ...item.unit_prices.map(price => ({
              ...price,
              productName: item.product_catalog.name,
              productId: item.product_catalog.id,
            })),
          ]
        })

        branchesComparative = branchesComparative.map(item => {
          if (item) return item
          return { unit_price: '-' }
        })
        // console.table(branchesComparative)
        branchesComparative.forEach(branch => {
          const userData = JSON.parse(localStorage.getItem('userData'))
          userData.scoped_roles.forEach(role => {
            if (role.role_resource_id === branch.supplier_branch_id) {
              suppliersWithProducts[branch.supplier_branch_id] = {
                supplier: role.role_resource_name,
              }
            }
          })
          if (suppliersWithProducts[branch.supplier_branch_id] === undefined) {
            suppliersWithProducts[branch.supplier_branch_id] = {
              supplier: `Proveedor ${Object.keys(suppliersWithProducts).length
                + 1}`,
            }
          }
          let unitPrice
          if (branch.unit_price > -1) {
            unitPrice = `$ ${this.$options.filters.money(
              branch.unit_price,
            )} MXN`
          } else {
            unitPrice = '-'
          }
          suppliersWithProducts[branch.supplier_branch_id][
            branch.productId
          ] = unitPrice
        })
        const fields = value.map(item => ({
          key: item.product_catalog.id,
          label: item.product_catalog.name,
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center align-middle text-wrap',
          thStyle: { width: '20%' },
        }))

        fields.unshift({
          key: 'supplier',
          label: this.$t('supplier'),
          stickyColumn: true,
          isRowHeader: true,
          thStyle: { width: '20%' },
          tdClass: 'text-nowrap',
          thClass: 'align-middle',
        })
        this.fields = fields
        this.items = Object.values(suppliersWithProducts)
        // const branchPricesT = this.transpose(
        //   quote.supplier_branches_comparative,
        // )
        // // const items = branchPricesT.slice(1).map((row, index)=>({supplier: row[0].name, supplierId: row[0].id, fields[index+1].id:row }))
        // const items = []
        // for (let i = 1; i < branchPricesT.length; i += 1) {
        //   const item = {
        //     supplier: branchPricesT[i][0].name,
        //     supplierId: branchPricesT[i][0].id,
        //   }
        //   for (let j = 1; j < branchPricesT[i].length; j += 1) {
        //     if (branchPricesT[i][j].unit_price > -1) {
        //       item[fields[j].key] = `$ ${this.$options.filters.money(
        //         branchPricesT[i][j].unit_price,
        //       )} MXN`
        //     } else {
        //       item[fields[j].key] = branchPricesT[i][j].unit_price
        //     }
        //   }
        //   items.push(item)
        // }
        // this.metadataFields = fields
        // this.metadataItems = items
        // this.loading = false
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
@media print {
  .print {
    overflow: visible;
  }
  .print-table {
    overflow-x: visible;
  }
  .noprint {
    display: none;
  }
}
</style>
